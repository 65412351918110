import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PatchSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/patch";

import { get, merge } from "lodash";

// Helper function to extract pagination details
const extractPaginationDetails = (responseData, queryParams) => ({
  pageNumber: queryParams.pageNumber ? queryParams.pageNumber + 1 : 1,
  size: get(responseData, "pageSize", 10),
  totalPages: get(responseData, "totalPages", 0),
  totalData: get(responseData, "totalData", 0),
});

const commitData = (commit, setStore, responseData, paginationDetails) => {
  const data = Array.isArray(responseData) ? responseData : get(responseData, "content", []);
  commit(setStore, merge({ data }, paginationDetails));
  return data;
};

const executeGetQuery = async (query, queryParams = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const response = await proxy.execute(query, queryParams);
  return response ? response.data : null;
};

const createUpdatePatient = async (_, { type = "add", payload, locationId, patientId }) => {
  const postProxy = new PostSimpatraServiceProxy();
  const putProxy = new PutSimpatraServiceProxy();
  const isUpdate = type === "update";
  let query = `jpatient/v1/location/${locationId}/patient`;
  if (isUpdate) {
    query += `/${patientId}`;
  }
  let response;

  if (isUpdate) {
    const successNotificationMsg = "Successfully updated patient!";
    response = await putProxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg,
    });
  } else {
    response = await postProxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully created patient.",
    });
  }

  if (response) {
    const { data } = response;
    return data;
  }
};

const createUpdatePatientUserInfo = async (_, { type = "add", payload, patientId }) => {
  const postProxy = new PostSimpatraServiceProxy();
  const putProxy = new PutSimpatraServiceProxy();
  const isUpdate = type === "update";
  let query = `j-idp/v1/patient/${patientId}`;
  let response;

  if (isUpdate) {
    const successNotificationMsg = "Successfully updated patient!";
    response = await putProxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg,
    });
  } else {
    query += "/activate";
    response = await postProxy.execute(query, payload, {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully activated Patient.",
    });
  }

  if (response) {
    const { data } = response;
    return data;
  }
};

const createPatientMergedInfo = async (_, { payload, locationId, isOriginFromMedcenter = false }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `jpatient/v1/location/${locationId}/patient/activate?isOriginFromMedcenter=${isOriginFromMedcenter}`;

  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully created patient.",
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const updatePatientSendInvoiceStatus = async (_, { locationId, payload, patientId }) => {
  const proxy = new PatchSimpatraServiceProxy();
  const query = `jpatient/v1/location/${locationId}/patient/${patientId}`;
  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully Update Patient Send Invoice Status.",
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchPatientList = async ({ commit }, { type, locationId, searchQuery = "", isLookups = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  const queryParams = {
    q: searchQuery,
  };
  if (type) {
    queryParams.type = type;
  }
  const query = `jpatient/v1/location/${locationId}/patient/search`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    if (isLookups) {
      return data;
    } else {
      commit("setPatientList", data);
    }
  }
};

const fetchPatientListCount = async ({ commit }, locationId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `jpatient/v1/location/${locationId}/patient/count`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setTotalPatient", {
      type: "init",
      value: data,
    });
    return data;
  }
};

const fetchPatientAllergyHistory = async (_, { patientId, pageNumber, pageSize, queryParams = {} }) => {
  const query = `jpatient/v1/patient/${patientId}/allergy-history`;

  // If pageNumber and pageSize are provided, append them as query parameters
  // pageNumber 0 means first page
  if ((pageNumber || pageNumber === 0) && (pageSize || pageSize === 0)) {
    queryParams.pageNumber = pageNumber;
    queryParams.pageSize = pageSize;
  }

  return await executeGetQuery(query, queryParams);
};

const addPatientAllergyHistory = async (_, { payload, patientId }) => {
  const postProxy = new PostSimpatraServiceProxy();
  const query = `jpatient/v1/patient/${patientId}/allergy-history`;

  const response = await postProxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added allergy history.",
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const updatePatientAllergyHistory = async (_, { payload, patientId, allergyHistoryId }) => {
  const putProxy = new PutSimpatraServiceProxy();
  const query = `jpatient/v1/patient/${patientId}/allergy-history/${allergyHistoryId}/status`;

  let successNotificationMsg = "";
  if (payload?.showNotif) {
    successNotificationMsg = "Successfully updated patient allergy history status!";
  }
  const response = await putProxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg,
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const deactivateAllPatientAllergyHistory = async (_, { payload, patientId }) => {
  const putProxy = new PutSimpatraServiceProxy();
  const query = `jpatient/v1/patient/${patientId}/nka`;

  const successNotificationMsg = "Successfully updated patient allergy history statuses!";
  const response = await putProxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg,
  });

  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchPatients = async ({ commit }, { queryParams = {} } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = "jpatient/v1/patients";
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const responseData = response.data;
    const paginationDetails = extractPaginationDetails(responseData, queryParams);
    const commitValues = queryParams.commitData ?? true;
    if (commitValues) {
      commitData(commit, "setPatients", responseData, paginationDetails);
    }
    return responseData;
  }
};

const createPatient = async (_, { queryParams = {}, payload = {} } = {}) => {
  const proxy = new PostSimpatraServiceProxy();
  let query = "jpatient/v1/patient/activate";
  if (Object.keys(queryParams).length > 0) {
    const isOriginFromMedcenter = queryParams.isOriginFromMedcenter || false;
    query += `?isOriginFromMedcenter=${isOriginFromMedcenter}`;
  }
  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully created patient.",
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

const updatePatient = async (_, { payload = {}, patientId = "" } = {}) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `jpatient/v1/patient/${patientId}`;
  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully updated patient.",
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

const assignPatientLocations = async (_, { payload = {}, patientId = {}, displaySuccessMessage = true } = {}) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `jpatient/v1/patient/${patientId}/assign-location`;
  const response = await proxy.execute(query, payload, {
    displaySuccessMessage,
    successNotificationMsg: "Location(s) successfully assigned to the patient.",
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

const getPatientAssignedLocations = async (_, { patientId = {} }) => {
  const query = `/jpatient/v1/patient/${patientId}/locations`;
  return await executeGetQuery(query, {});
};

export default {
  createUpdatePatient,
  createUpdatePatientUserInfo,
  createPatientMergedInfo,
  fetchPatientList,
  updatePatientSendInvoiceStatus,
  fetchPatientListCount,
  fetchPatientAllergyHistory,
  addPatientAllergyHistory,
  updatePatientAllergyHistory,
  deactivateAllPatientAllergyHistory,
  // Updated APIs
  fetchPatients,
  createPatient,
  updatePatient,
  assignPatientLocations,
  getPatientAssignedLocations,
};
