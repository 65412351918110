import Vue from "vue";
import Router from "vue-router";
import { notification } from "ant-design-vue";
import { hasPatientChartAccess, allowToOnboard, allowUserToNavigateBulkOrder } from "./router-guard";
import MainContent from "@/components/common/MainContent.vue";
import NotFound from "@/components/common/NotFound.vue";
import Auth0Callback from "@/views/Auth0Callback.vue";

const OrgLocations = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/dashboard/org/OrgLocations.vue");
};
const OrgUserManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/dashboard/org-user-management/OrgUserManagement.vue");
};
const OrgPaymentMethods = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/dashboard/org/OrgPaymentMethod/OrgPaymentMethods.vue");
};
const PracticeReceipts = () => {
  return import(/* webpackChunkName: "organization" */ "@/components/admin-panel/PracticeReceipts/PracticeReceipts.vue");
};
const SubscriptionReceipts = () => {
  return import(/* webpackChunkName: "BioteAdmin" */ "@/components/shared-components/Invoice/Receipts/SubscriptionReceipts.vue");
};
const OrgReceipts = () => {
  return import(/* webpackChunkName: "organization" */ "@/components/dashboard/org/OrgReceipts.vue");
};
const OrgContactInfo = () => {
  return import(/* webpackChunkName: "organization" */ "@/components/dashboard/org/OrgContactInfo.vue");
};
// Location Chunk
const LocationDashboard = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/dashboard/LocationDashboard.vue");
};
const PatientsApp = () => {
  return import(/* webpackChunkName: "PatientsApp" */ "@/components/apps/PatientsApp.vue");
};
const LocationSettings = () => {
  return import(/* webpackChunkName: "LocationSettings" */ "@/components/admin-panel/Locations/LocationSettings/LocationSettings.vue");
};
const PatientDetails = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/dashboard/patient-chart/PatientDetails.vue");
};
const PatientList = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/dashboard/patient-chart/PatientList.vue");
};
const PlaceOrder503A = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/dashboard/patient-chart/Prescription/PlaceOrder/PlaceOrder.vue");
};
const SearchProduct503A = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/dashboard/patient-chart/Prescription/AddPrescriptions/SearchProduct/SearchProduct.vue");
};
const DashboardHomeRx = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/biote/DashboardHomeRx/DashboardHomeRx.vue");
};
const DashboardOrderHistory = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/dashboard/order-history/DashboardOrderHistory.vue");
};
const Marketplace503b = () => {
  return import(/* webpackChunkName: "Marketplace" */ "@/components/dashboard/marketplace/Marketplace503b.vue");
};
const CartLayout = () => {
  return import(/* webpackChunkName: "Marketplace" */ "@/components/dashboard/marketplace/CartLayout/CartLayout.vue");
};
const DosingApp = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/apps/DosingApp.vue");
};
const Provider = () => {
  return import(/* webpackChunkName: "PatientChart" */ "@/components/apps/provider/Provider.vue");
};

// Vendor Chunk
const VendorDashboard = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorDashboard.vue");
};
const VendorOrders = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorOrders.vue");
};
const VendorOrderList = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorOrderList.vue");
};
const VendorInvoice = () => {
  return import(/* webpackChunkName: "VendorInvoice" */ "@/components/vendor/order/VendorInvoice/VendorInvoice.vue");
};
const VendorProducts = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/VendorProducts.vue");
};
const VendorTiers = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/vendor/tier/VendorTiers.vue");
};
const VendorRegistrations = () => {
  return import(/* webpackChunkName: "VendorRegistrations" */ "@/components/vendor-module/registrations/VendorRegistrations.vue");
};
const VendorUsers = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/vendor/vendor-user-management/VendorUsers.vue");
};
const ActiveInventory = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/ActiveInventory.vue");
};
const FulfilledInventory = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/FulfilledInventory.vue");
};
const VendorSettings = () => {
  return import(/* webpackChunkName: "VendorSettings" */ "@/components/vendor-module/settings/VendorSettings.vue");
};
const WarehouseInventory = () => {
  return import(/* webpackChunkName: "seller" */ "@/components/dashboard/WarehouseInventory.vue");
};
// Admin Chunk
const SimpatraAdmin = () => {
  return import(/* webpackChunkName: "admin" */ "@/views/SimpatraAdmin.vue");
};
const VendorManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/VendorManagement.vue");
};
const OrganizationManagament = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/OrgManagement.vue");
};
const OrganizationList = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/OrganizationManagement/OrgList.vue");
};
const OrgManageLocations = () => {
  return import(/* webpackChunkName: "OrgManageLocations" */ "@/components/dashboard/org/OrgLocations/OrgManageLocations/OrgManageLocations.vue");
};
const OrgManageUsers = () => {
  return import(/* webpackChunkName: "OrgManageUsers" */ "@/components/dashboard/org/OrgLocations/OrgManageUsers/OrgManageUsers.vue");
};
const VendorRelationshipManager = () => {
  return import(/* webpackChunkName: "VendorRelationshipManager" */ "@/components/admin/OrganizationManagement/VendorRelationshipManager.vue");
};
const SubscriptionManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/SubscriptionManagement.vue");
};
const DirectoryManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/DirectoryManagement.vue");
};
const UserManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/UserManagement.vue");
};
const QuizManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/QuizManagement.vue");
};
const AdminReport = () => {
  return import(/* webpackChunkName: "AdminReport" */ "@/components/admin-report/AdminReport.vue");
};

const RolesAndPermissions = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/RolesAndPermissions/RolesAndPermissions.vue");
};

const RolesUserManagement = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/RolesAndPermissions/UserManagement/UserManagement.vue");
};

const ModifyRoles = () => {
  return import(/* webpackChunkName: "admin" */ "@/components/admin/RolesAndPermissions/ModifyRoles/ModifyRoles.vue");
};

const Onboarding = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/Onboarding.vue");
};
const OnboardingForm = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/OnboardingForm/OnboardingForm.vue");
};
const PracticeInformation = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/PracticeOnboardingForm/components/PracticeInformation.vue");
};
const AddressInformation = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/AddressInformation.vue");
};
const BusinessAssociateAgreement = () => {
  return import(/* webpackChunkName: "BusinessAssociateAgreement" */ "@/components/onboarding/shared/components/BusinessAssociateAgreement.vue");
};
const AccountSetup = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/AccountSetup.vue");
};
const LicenseSetup = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/LicenseSetup.vue");
};
const OnboardingEULA = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/EULA.vue");
};
const GetStarted = () => {
  return import(/* webpackChunkName: "onboarding" */ "@/components/onboarding/shared/components/GetStarted.vue");
};
const Signup = () => {
  return import(/* webpackChunkName: "Signup" */ "@/components/signup/Signup.vue");
};
const ReportOrder = () => {
  return import(/* webpackChunkName: "ReportOrder" */ "@/components/admin-report/ReportOrder/ReportOrder.vue");
};
const PurchaseOrder = () => {
  return import(/* webpackChunkName: "organization" */ "@/components/dashboard/org/PurchaseOrder.vue");
};
const ReportsList = () => {
  return import(/* webpackChunkName: "ReportsList" */ "@/components/admin-report/ReportsList/ReportsList.vue");
};
const Privacy = () => {
  return import(/* webpackChunkName: "privacy" */ "@/views/privacy.vue");
};
const TermsOfService = () => {
  return import(/* webpackChunkName: "termsOfService" */ "@/views/terms-of-service.vue");
};
const ReturnsAndCancellationPolicy = () => {
  return import(/* webpackChunkName: "returnsAndCancellationPolicy" */ "@/components/privacy-terms-of-service/ReturnsAndCancellationPolicy.vue");
};
const TermsOfUse = () => {
  return import(/* webpackChunkName: "termsOfUse" */ "@/components/privacy-terms-of-service/TermsOfUse.vue");
};

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: MainContent,
      children: [
        {
          path: "/simpatra",
          name: "SimpatraAdmin",
          component: SimpatraAdmin,
          meta: { authRequired: true, simpatraAdmin: true },
          children: [
            {
              path: "vendor",
              component: VendorManagement,
              name: "VendorManagement",
              props: true,
            },
            {
              path: "organization",
              component: OrganizationManagament,
              props: true,
              children: [
                {
                  path: "/",
                  component: OrganizationList,
                  name: "OrganizationManagement",
                  props: true,
                },
                {
                  path: "vendor-relationship-manager",
                  component: VendorRelationshipManager,
                  name: "VendorRelationshipManager",
                  props: true,
                },
                {
                  path: ":orgId/locations",
                  component: OrgManageLocations,
                  name: "OrgManageLocations",
                  props: true,
                },
                {
                  path: ":orgId/users",
                  component: OrgManageUsers,
                  name: "OrgManageUsers",
                  props: true,
                },
              ],
            },
            {
              path: "subscription",
              component: SubscriptionManagement,
              name: "SubscriptionManagement",
              props: true,
            },
            {
              path: "directory",
              component: DirectoryManagement,
              name: "DirectoryManagement",
              props: true,
            },
            {
              path: "directory",
              component: DirectoryManagement,
              // meta: { orgAdmin: true, authRequired: true },
              children: [
                {
                  path: "providers",
                  component: UserManagement,
                  name: "UserManagement",
                },
                {
                  path: "quiz-details",
                  component: QuizManagement,
                  name: "QuizManagement",
                  props: true,
                },
              ],
            },
            {
              path: "admin-report",
              meta: { simpatraAdminAccessRequired: true, simpatraReports: true },
              component: AdminReport,
              children: [
                {
                  path: "/",
                  component: ReportsList,
                  name: "ReportsList",
                  props: true,
                },
                {
                  path: "purchase/:generatedId",
                  component: ReportOrder,
                  name: "ReportOrder",
                  props: true,
                },
                {
                  path: "subscription/:subscriptionId",
                  component: SubscriptionReceipts,
                  name: "SubscriptionReceipts",
                  props: true,
                },
              ],
            },
            {
              path: "roles-and-permissions",
              meta: { simpatraAdminAccessRequired: true },
              component: RolesAndPermissions,
              children: [
                {
                  path: "", // Use an empty string for the default child route
                  component: RolesUserManagement,
                  name: "RolesUserManagement",
                },
                {
                  path: "modify-roles/:userId", // Remove the leading slash
                  component: ModifyRoles,
                  name: "ModifyRoles",
                  props: true,
                },
              ],
            },
          ],
        },
        // --------------------------------
        // BEGIN DASHBOARD CONFIG
        {
          path: "/",
          name: "Provider",
          component: Provider,
          children: [
            {
              path: "clinic-admin",
              component: DosingApp,
              meta: { orgAdmin: true, authRequired: true },
              children: [
                {
                  path: "users",
                  component: OrgUserManagement,
                  name: "OrgUserManagement",
                  meta: { isClinicAdmin: true },
                },
                {
                  path: "locations",
                  component: OrgLocations,
                  name: "OrgLocations",
                  props: true,
                  meta: { isClinicAdmin: true },
                },
                // {
                //   path: "subscriptions",
                //   component: OrgSubscriptions,
                //   name: "OrgSubscriptions",
                // },
                {
                  path: "payment-methods",
                  component: OrgPaymentMethods,
                  name: "OrgPaymentMethods",
                  meta: { isClinicAdmin: true },
                },
                {
                  path: "receipts",
                  component: PracticeReceipts,
                  meta: { isClinicAdmin: true },
                  children: [
                    {
                      path: "list-view",
                      component: OrgReceipts,
                      name: "OrgReceipts",
                    },
                  ],
                },
                {
                  path: "contact-info",
                  component: OrgContactInfo,
                  name: "OrgContactInfo",
                  meta: { isClinicAdmin: true },
                },
                {
                  path: "purchase-invoice/:generatedId",
                  component: PurchaseOrder,
                  name: "PurchaseOrder",
                  props: true,
                },
              ],
            },
            // Location Dashboards
            {
              path: "/dashboard/:locationId",
              component: LocationDashboard,
              props: true,
              meta: { locationRequired: true, authRequired: true },
              children: [
                {
                  path: "patient-chart",
                  component: DosingApp,
                  props: true,
                  children: [
                    {
                      path: "/",
                      name: "DashboardHomeRx",
                      component: DashboardHomeRx,
                      props: true,
                    },
                    {
                      path: "patients",
                      component: PatientsApp,
                      beforeEnter: (to, from, next) => {
                        /**
                         * USER CAN'T GO TO PATIENT CHART
                         * IF SCHEDULING DOES NOT INCLUDED IN THE PERMISSIONS
                         */
                        const hasAccess = hasPatientChartAccess();
                        if (hasAccess) {
                          next();
                        } else {
                          notification.error({
                            message: "You don't have scheduling permission to proceed patient chart!",
                            duration: 4000,
                          });
                          next(false);
                        }
                      },
                      children: [
                        {
                          path: "",
                          name: "PatientsList",
                          component: PatientList,
                          props: true,
                        },
                        {
                          path: ":patientId",
                          name: "PatientDetails",
                          component: PatientDetails,
                          props: true,
                        },
                        {
                          path: ":patientId/search-product",
                          name: "SearchProduct503A",
                          component: SearchProduct503A,
                          props: true,
                        },
                        {
                          path: ":patientId/place-order",
                          name: "PlaceOrder503A",
                          component: PlaceOrder503A,
                          props: true,
                        },
                      ],
                    },
                    {
                      path: "order-history",
                      name: "DashboardOrderHistory",
                      component: DashboardOrderHistory,
                      props: true,
                    },
                    // {
                    //   path: "fulfillments",
                    //   component: LocationMedcenterOrders,
                    //   props: true,
                    //   children: [
                    //     {
                    //       path: "/",
                    //       name: "LocationActivationList",
                    //       component: LocationActivationList,
                    //     },
                    //     {
                    //       path: "location-order",
                    //       name: "LocationOrderDetails",
                    //       component: LocationOrderDetails,
                    //     },
                    //   ],
                    // },
                  ],
                },
                {
                  path: "settings/:tabDefault?",
                  name: "LocationSettings",
                  component: LocationSettings,
                  props: true,
                },
              ],
            },
            {
              path: "/marketplace/:locationId",
              component: DosingApp,
              props: true,
              meta: { locationRequired: true, authRequired: true },
              beforeEnter: allowUserToNavigateBulkOrder,
              children: [
                {
                  path: "/",
                  name: "Marketplace503b",
                  component: Marketplace503b,
                  props: true,
                },
                {
                  path: "cart-order",
                  name: "CartOrder",
                  component: CartLayout,
                  props: true,
                },
              ],
              // component: Marketplace503b,
              // props: true,
              // meta: { locationRequired: true, authRequired: true },
            },
          ],
        },
        {
          path: "/dashboard/vendor/:vendorId",
          component: VendorDashboard,
          props: true,
          meta: { authRequired: true, checkVendorAccessibility: true },
          children: [
            {
              path: "orders",
              component: VendorOrders,
              props: true,
              meta: { checkVendorAccessibility: true },
              children: [
                {
                  path: "/",
                  component: VendorOrderList,
                  name: "VendorOrderList",
                  props: true,
                  meta: { checkVendorAccessibility: true },
                },
                {
                  path: ":orderId/:purchaseType",
                  component: VendorInvoice,
                  name: "VendorInvoice",
                  props: true,
                  meta: { checkVendorAccessibility: true },
                },
              ],
            },
            {
              path: "products",
              component: VendorProducts,
              name: "VendorProductManagement",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "tiers",
              component: VendorTiers,
              name: "VendorTiers",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "registrations",
              component: VendorRegistrations,
              name: "VendorRegistrations",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "users",
              component: VendorUsers,
              name: "VendorUsers",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "warehouse",
              component: WarehouseInventory,
              name: "WarehouseInventory",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "activeinventory",
              component: ActiveInventory,
              name: "ActiveInventory",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "fulfilledinventory",
              component: FulfilledInventory,
              name: "FulfilledInventory",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
            {
              path: "settings",
              component: VendorSettings,
              name: "VendorSettings",
              props: true,
              meta: { checkVendorAccessibility: true },
            },
          ],
        },
        {
          path: "/privacy-policy",
          name: "Privacy",
          component: Privacy,
        },
        {
          path: "/terms-of-service",
          name: "TermsOfService",
          component: TermsOfService,
        },
        {
          path: "/returns-and-cancellation-policy",
          name: "ReturnsAndCancellationPolicy",
          component: ReturnsAndCancellationPolicy,
        },
        {
          path: "/terms-of-use",
          name: "TermsOfUse",
          component: TermsOfUse,
        },
        // {
        //   path: "/my-profile",
        //   component: MyProfileView,
        //   meta: { authRequired: true },
        //   children: [
        //     {
        //       path: "/",
        //       name: "MyProfile",
        //       component: MyProfile,
        //     },
        //   ],
        // },
        // {
        // END CHECKOUT CONFFIG
        // --------------------------------
      ],
    },
    {
      path: "/callback",
      name: "callback",
      component: Auth0Callback,
    },
    {
      path: "/id-me/callback",
      name: "IDMeCallback",
      component: Auth0Callback,
    },
    // ONBOARDING SETUP
    {
      path: "/practice-onboarding-setup",
      component: Onboarding,
      props: true,
      beforeEnter: allowToOnboard,
      children: [
        {
          path: "/",
          name: "PracticeOnboardingForm",
          component: OnboardingForm,
          meta: { totalSteps: 6 },
          children: [
            {
              path: "business-information",
              name: "PracticeInformation",
              component: PracticeInformation,
              meta: { onboarding: true, step: 1 },
            },
            {
              path: "address-information",
              name: "PracticeAddressInformation",
              component: AddressInformation,
              meta: { onboarding: true, step: 2 },
            },
            {
              path: "business-associate-agreement",
              name: "PracticeBusinessAssociateAgreement",
              component: BusinessAssociateAgreement,
              meta: { onboarding: true, step: 3 },
            },
            {
              path: "end-user-license-agreement",
              name: "PracticeEndUserLicenseAgreement",
              component: OnboardingEULA,
              meta: { onboarding: true, step: 4 },
            },
            {
              path: "practice-account-setup",
              name: "PracticeUserAccountSetup",
              component: AccountSetup,
              meta: { onboarding: true, step: 5 },
            },
            {
              path: "practice-license-setup",
              name: "PracticeUserLicenseSetup",
              component: LicenseSetup,
              meta: { onboarding: true, step: 6 },
            },
            {
              path: "get-started",
              name: "PracticeGetStarted",
              component: GetStarted,
              meta: { onboarding: true, step: 6, isPractice: true },
            },
          ],
        },
      ],
    },
    // {
    //   path: "/vendor-onboarding-setup",
    //   name: "OnboardingVendor",
    //   component: Onboarding,
    //   props: true,
    //   children: [
    //     {
    //       path: "/",
    //       name: "OnboardingForm",
    //       component: OnboardingForm,
    //       meta: { totalSteps: 7 },
    //       children: [
    //         {
    //           path: "business-information",
    //           name: "VendorInformation",
    //           component: VendorInformation,
    //           meta: { step: 1 },
    //         },
    //         {
    //           path: "address-information",
    //           name: "AddressInformation",
    //           component: AddressInformation,
    //           meta: { step: 2 },
    //         },
    //         {
    //           path: "business-associate-agreement",
    //           name: "BusinessAssociateAgreement",
    //           component: BusinessAssociateAgreement,
    //           meta: { step: 3 },
    //         },
    //         {
    //           path: "vendor-agreement",
    //           name: "VendorLicenseAgreement",
    //           component: VendorLicenseAgreement,
    //           meta: { step: 4 },
    //         },
    //         {
    //           path: "end-user-license-agreement",
    //           name: "VendorEndUserSetup",
    //           component: OnboardingEULA,
    //           meta: { step: 5 }
    //         },
    //         {
    //           path: "account-setup",
    //           name: "VendorUserAccountSetup",
    //           component: AccountSetup,
    //           meta: { step: 6 },
    //         },
    //         {
    //           path: "get-started",
    //           name: "VendorGetStarted",
    //           component: GetStarted,
    //           meta: { step: 7, isVendor: true },
    //         },
    //       ],
    //     },
    //   ],
    // },
    // END OF ONBOARDING SETUP
    // {
    //   path: "/vendor-onboarding-setup/set-appointment",
    //   name: "SetAppointment",
    //   component: SetAppointment,
    // },
    {
      path: "/signup-options",
      name: "Signup",
      component: Signup,
    },
    {
      path: "/404",
      name: "PageNotFound",
      component: NotFound,
    },
    {
      path: "*",
      beforeEnter() {
        window.location.href = "/404";
      },
    },
  ],
});
