import axiosProxy from "axios";
import axios from "@/lib/axios";
import { getUrlExtension, convertBlobFileUrl } from "@/utils/file-reader-helper";
import notification from "@/utils/notifications";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchS3BucketFile = async (_, { fileName, repository }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/outreach/download/${repository}/${fileName}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    return data;
  }
};

const uploadLicenseFile = async (
  _,
  { formData, updateFileProgress, file, newFile, onSuccess, onError, onProgress }
) => {
  try {
    const config = {
      headers: {
        Accept: "*/*",
      },
    };
    if (updateFileProgress) {
      config.onUploadProgress = progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateFileProgress({ file, percentCompleted });
        onProgress({ percent: percentCompleted }, newFile);
      };
    }
    const response = await axios.post("bucket/v1/license", formData, config);
    if (response) {
      const status = "done";
      updateFileProgress({ file, status });
      onSuccess(response.data, file);
      notification("success", "File uploaded successfully!");
      return response;
    }
  } catch (error) {
    const status = "error";
    if (updateFileProgress) {
      updateFileProgress({ file, status });
      onError("error", file);
    }
    notification("warning", "File upload failed!");
    return null;
  }
};

const uploadImgFile = async (_, { formData, updateFileProgress, file, newFile, onSuccess, onError, onProgress }) => {
  try {
    const query = "bucket/v1/image";
    const config = {
      headers: {
        Accept: "*/*",
      },
    };
    if (updateFileProgress) {
      config.onUploadProgress = progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateFileProgress({ file, percentCompleted });
        onProgress({ percent: percentCompleted }, newFile);
      };
    }
    const response = await axios.post(query, formData, config);
    if (response) {
      const status = "done";
      if (updateFileProgress) {
        updateFileProgress({ file, status });
        onSuccess(response.data, file);
      }
      notification("success", "File uploaded successfully!");
      return response;
    }
  } catch (error) {
    const status = "error";
    if (updateFileProgress) {
      updateFileProgress({ file, status });
      onError("error", file);
    }
    notification("warning", "File upload failed!");
    return null;
  }
};

const fetchFileToDownload = async (_, file) => {
  const url = file.fileLocation;
  const ext = getUrlExtension(file.fileLocation);
  const filename = `${file.id}.${ext}`;
  axiosProxy({
    url,
    method: "GET",
    responseType: "blob",
  })
    .then(response => {
      if (response.data) {
        const fileURL = convertBlobFileUrl(response.data);
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    })
    .catch(() => {
      notification("warning", "Failed uploading the file!");
      return null;
    });
};

const uploadVendorProductFile = async (_, { payload, formData, updateFileProgress, file, newFile, onSuccess, onError, onProgress }) => {
  try {
    const query = `/bucket/v1/vendor/${payload.vendorId}/product/${payload.slug}/file`;
    const config = {
      headers: {
        Accept: "*/*",
      },
    };
    if (updateFileProgress) {
      config.onUploadProgress = progressEvent => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateFileProgress({ file, percentCompleted });
        onProgress({ percent: percentCompleted }, newFile);
      };
    }
    const response = await axios.post(query, formData, config);
    if (response) {
      const status = "done";
      if (updateFileProgress) {
        updateFileProgress({ file, status });
        onSuccess(response.data, file);
        notification("success", "File uploaded successfully!");
      }
      return response;
    }
  } catch (error) {
    const status = "error";
    if (updateFileProgress) {
      updateFileProgress({ file, status });
      onError("error", file);
    }
    notification("warning", "File upload failed!");
    return null;
  }
};

export default {
  fetchS3BucketFile,
  uploadLicenseFile,
  fetchFileToDownload,
  uploadImgFile,
  uploadVendorProductFile,
};
