const defaultPaginationDetails = {
  pageNumber: 1,
  totalPages: 0,
  pageSize: 5,
  totalData: 0,
};

const initialProductListingFilters = {
  categorySlug: [],
  vendorId: undefined,
  sortName: undefined,
  sortOrder: undefined,
  searchText: undefined,
  ...defaultPaginationDetails,
};

const createObject = () => ({
  data: [],
  filters: initialProductListingFilters,
  paginationDetails: defaultPaginationDetails,
});

export default {
  defaultPaginationDetails,
  initialProductListingFilters,
  isFetching: false,
  isLoading: false,
  displayType: "list-view", // Grid View or List View
  categories: [
    { id: 1, name: "Pharmaceuticals", value: "pharmaceuticals" },
    { id: 2, name: "Nutraceuticals", value: "nutraceuticals" },
  ],
  selectedCategories: [],
  cartShippingRates: null,
  cartPayload: {
    ccId: null,
    orgId: null,
    shippingRateIds: [],
  },
  cartStatus: "processing", // Processing, Success
  productListing: createObject(),
  currentStep: 0,
  cartStepper: "cart-summary",
  shippingRates: [],
  cartSummary: null,
  cartProductList: [],
  cartSubTotal: 0,
  totalLineItemQty: null,
  cartGrandTotal: 0,
};
