import { get, merge } from "lodash";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

// Helper function to extract pagination details
const extractPaginationDetails = (responseData, queryParams) => ({
  pageNumber: queryParams.pageNumber ? queryParams.pageNumber : 0,
  size: get(responseData, "pageSize", 5),
  totalPages: get(responseData, "totalPages", 0),
  totalData: get(responseData, "totalData", 0),
});

const commitData = (commit, setStore, responseData, paginationDetails) => {
  const data = Array.isArray(responseData) ? responseData : get(responseData, "content", []);
  commit(setStore, merge({ data }, paginationDetails));
  return data;
};

const manageLoadingState = async (commit, action, callback) => {
  commit("setCartState", { type: "isLoading", value: true });
  const result = await callback();
  commit("setCartState", { type: "isLoading", value: false });
  return result;
};

const executePostQuery = async (query, payload, notificationParams) => {
  const proxy = new PostSimpatraServiceProxy();
  const response = await proxy.execute(query, payload, notificationParams);
  return response ? response.data : null;
};

const executePutQuery = async (query, payload, notificationParams) => {
  const proxy = new PutSimpatraServiceProxy();
  const response = await proxy.execute(query, payload, notificationParams);
  return response ? response.data : null;
};
const executeDeleteQuery = async (query, notificationParams) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const response = await proxy.execute(query, { notificationParams });
  return response ? response.data : null;
};

const updateCartLineItem = async ({ commit }, payload) => {
  const query = `orders/v1/cart/${payload.locationId}/item/${payload.lineItemId}`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: `Product ${payload.productName} has been ${payload.type === "update" ? "updated" : "deleted"} successfully!`,
    successNotificationHeader: `Successfully ${payload.type === "update" ? "Updated" : "Deleted"}!`,
  };

  return manageLoadingState(commit, payload.type, async () => {
    let response;
    if (payload.type === "update") {
      response = await executePutQuery(query, payload.data, notificationParams);
      commit("updateCartItem", payload);
    } else if (payload.type === "remove") {
      response = await executeDeleteQuery(query, notificationParams);
      if (response) {
        commit("updateCartDisparityList", payload);
        commit("updateCartItem", payload);
      }
    }
    return response;
  });
};

const fetchProductListing = async ({ commit, state }, { locationId } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `products/v1/location/${locationId}/market/products`;
  commit("setFetching", true);
  const response = await proxy.execute(query, state.productListing.filters);
  const responseData = response.data || [];
  const paginationDetails = extractPaginationDetails(responseData, state.productListing.filters);
  commit("setFetching", false);
  commit("setProductListingSearch", paginationDetails);
  return commitData(commit, "setProductListing", responseData, paginationDetails);
};

const addToCart = async ({ commit }, { locationId, productName, payload } = {}) => {
  const query = `orders/v1/cart/${locationId}/item`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationMsg: `Product ${productName} was added to cart successfully!`,
    successNotificationHeader: "Successfully Added!",
  };
  return await executePostQuery(query, payload, notificationParams);
};

const fetchCartSummary = async ({ commit }, { locationId } = {}) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `orders/v1/buyer/${locationId}/purchase`;
  const response = await proxy.execute(query);
  commit("setCartState", {
    type: "cartSummary",
    value: response.data || null,
  });
  return response.data || null;
};

const fetchCartLineItems = async ({ commit }, { locationId } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/cart/${locationId}`;
  const response = await proxy.execute(query);
  const cartItems = response.data.cartItems;
  const cartSubTotal = cartItems.reduce((total, item) => {
    return total + item.qty * item.price;
  }, 0);
  commit("setCartState", {
    type: "cartProductList",
    value: cartItems || null,
  });
  commit("setCartState", {
    type: "cartSubTotal",
    value: parseFloat(cartSubTotal.toFixed(2)) || 0,
  });
};

const finalizeOrder = async (_, { locationId, purchasedId, payload } = {}) => {
  const query = `orders/v1/buyer/${locationId}/purchase/${purchasedId}/finalize`;
  const notificationParams = {
    displaySuccessMessage: true,
    successNotificationHeader: "Order Placed Successfully!",
    successNotificationMsg: "Your order has been securely sent for fulfillment.",
  };
  return await executePostQuery(query, payload, notificationParams);
};

const fetchTotalCartLineItems = async ({ commit }, { locationId } = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `orders/v1/cart/${locationId}/item-total`;
  const response = await proxy.execute(query);
  commit("setCartState", {
    type: "totalLineItemQty",
    value: response.data.totalLineItemQty || 0,
  });
  commit("setCartState", {
    type: "cartGrandTotal",
    value: response.data.grandTotal || 0,
  });
};

export default {
  updateCartLineItem,
  fetchProductListing,
  addToCart,
  fetchCartSummary,
  fetchCartLineItems,
  finalizeOrder,
  fetchTotalCartLineItems,
};
