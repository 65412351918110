const defaultPaginationDetails = {
  pageNumber: 1,
  totalPages: 0,
  pageSize: 5,
  totalData: 0,
};

const initialProductListingFilters = {
  categorySlug: [],
  vendorId: undefined,
  sortName: undefined,
  sortOrder: undefined,
  searchText: undefined,
  ...defaultPaginationDetails,
};

const createObject = () => ({
  data: [],
  filters: initialProductListingFilters,
  paginationDetails: defaultPaginationDetails,
});

export default {
  creditCards: [],
};
