const getCartState = state => state;
const getProductListing = state => state.productListing;
const getProductCategories = state => state.categories;
const getCartSummary = state => state.cartSummary;
const getAllCartItems = state => state.cartProductList;
const getCartTotal = state => {
  return state.cartProductList.reduce((total, item) => {
    return total + item.qty * item.price;
  }, 0);
};
const getTotalCartLineItems = state => state.totalLineItemQty;
const getCartGrandTotal = state => state.cartGrandTotal || 0;
const getShippingTotal = state => {
  return state.cartPayload.shippingRateIds.reduce((total, item) => {
    return total + item.value.rate;
  }, 0);
};

export default {
  getCartSummary,
  getAllCartItems,
  getProductListing,
  getProductCategories,
  getCartState,
  getCartTotal,
  getTotalCartLineItems,
  getShippingTotal,
  getCartGrandTotal,
};
