import { get } from "lodash";

const setDataAndPagination = (state, key, payload) => {
  if (payload) {
    const data = get(payload, "data", []);
    state[key].paginationDetails.currentPage = get(payload, "pageNumber", 1);
    state[key].paginationDetails.totalPages = get(payload, "totalPages", 0);
    state[key].paginationDetails.pageSize = get(payload, "size", 10);
    state[key].paginationDetails.totalData = get(payload, "totalData", 0);
    state[key].data = data;
  } else {
    state[key].data = [];
    state[key].paginationDetails = state.defaultPaginationDetails;
  }
};

const setPatientList = (state, payload) => {
  if (typeof payload === "object" && payload.type === "add") {
    state.patientList.unshift(payload.data);
  } else {
    state.patientList = payload;
  }
};
const setTotalPatient = (state, payload) => {
  if (payload.type === "init") {
    state.totalPatient = payload.value;
  } else if (payload.type === "add" && state.totalPatient) {
    state.totalPatient = {
      count: state.totalPatient.count + 1,
      recentlyAddedCount: state.totalPatient.recentlyAddedCount + 1,
    };
  }
};

const setPatients = (state, payload) => {
  setDataAndPagination(state, "patients", payload);
};

export default {
  setPatientList,
  setTotalPatient,
  setPatients,
};
