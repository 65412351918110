import { get } from "lodash";

// Generic function to set data and pagination details
const setDataAndPagination = (state, key, payload) => {
  if (payload) {
    const data = get(payload, "data", []);
    state[key].paginationDetails.currentPage = get(payload, "pageNumber", 0);
    state[key].paginationDetails.totalPages = get(payload, "totalPages", 0);
    state[key].paginationDetails.pageSize = get(payload, "size", 10);
    state[key].paginationDetails.totalData = get(payload, "totalData", 0);
    state[key].data = data;
  } else {
    state[key].data = [];
    state[key].paginationDetails = state.defaultPaginationDetails;
  }
};

const setProductListing = (state, payload) => {
  setDataAndPagination(state, "productListing", payload);
};

const setProductListingSearch = (state, payload) => {
  if (payload.isReset) {
    state.productListing.filters = {
      categorySlug: [],
      vendorId: undefined,
      sortName: undefined,
      sortOrder: undefined,
      searchText: undefined,
      ...state.defaultPaginationDetails,
    };
  } else {
    if (payload.type) {
      state.productListing.filters[payload.type] = payload.value;
    } else {
      state.productListing.filters = {
        ...state.productListing.filters,
        ...payload,
      };
    }
  }
};

const setCartState = (state, payload) => {
  state[payload.type] = payload.value;
};

const setFetching = (state, payload) => {
  state.isFetching = payload;
};

const updateCartItem = (state, payload) => {
  // Find the vendor cart group
  const vendorCartIndex = state.cartProductList.findIndex(product => product.id === payload.lineItemId);
  if (vendorCartIndex !== -1) {
    switch (payload.type) {
      case "update":
        // Update quantity or other item properties
        state.cartProductList[vendorCartIndex] = {
          ...state.cartProductList[vendorCartIndex],
          ...payload.data,
        };
        break;
      case "remove":
        // Remove the specific item
        state.cartProductList.splice(vendorCartIndex, 1);
        break;
    }
  }
  // Calculate subtotal after the switch statement
  state.cartSubTotal = parseFloat(calculateTotal(state.cartProductList).toFixed(2));
};

const calculateTotal = data => {
  return data.reduce((total, item) => {
    return total + item.qty * item.price;
  }, 0);
};

const updateCartStepper = (state, payload) => {
  if (payload.type === "next") {
    state.currentStep++;
    state.cartStepper = cartStepper(state.currentStep);
  } else if (payload.type === "back" && state.currentStep > 0) {
    state.currentStep--;
    state.cartStepper = cartStepper(state.currentStep);
  } else {
    state.currentStep = 0;
    state.cartStepper = "cart-summary";
    state.cartStatus = "processing";
  }
};

const cartStepper = currentStep => {
  switch (currentStep) {
    case 0:
      return "cart-summary";
    case 1:
      return "shipping";
    case 2:
      return "review-order";
    case 3:
      return "payment";
  }
};

const setCartPayload = (state, payload) => {
  if (payload.isReset) {
    state.cartPayload = {
      ccId: null,
      orgId: null,
      shippingRateIds: [],
    };
  } else if (payload.prop === "shippingRateIds") {
    const fulfillmentShipping = state.cartPayload.shippingRateIds.findIndex(fulfillment => fulfillment.id === payload.fulfillmentId);
    if (fulfillmentShipping >= 0) {
      state.cartPayload.shippingRateIds[fulfillmentShipping].value = payload.value;
    } else {
      state.cartPayload.shippingRateIds.push({
        id: payload.fulfillmentId,
        value: payload.value,
      });
    }
  } else {
    state.cartPayload[payload.prop] = payload.value;
  }
};

const updateCartDisparityList = (state, payload) => {
  const { disparities } = state.cartSummary || {};
  if (disparities && disparities.length > 0) {
    const disparityProductIndex = disparities.findIndex(item => item.cartItem.id === payload.lineItemId);
    if (disparityProductIndex !== -1) {
      disparities.splice(disparityProductIndex, 1);
    }
  }
};

export default {
  setCartPayload,
  updateCartItem,
  setProductListing,
  setProductListingSearch,
  setFetching,
  setCartState,
  updateCartStepper,
  updateCartDisparityList,
};
