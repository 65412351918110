import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";
import globalUtil from "@/utils/global";
// import userMe from "@/services/user-me";

const executeGetQuery = async (query, queryParams = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const response = await proxy.execute(query, queryParams);
  return response ? response.data : null;
};

const fetchVendorUsers = async ({ commit }, { vendorId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `/accounts/v1/vendor/${vendorId}/user`;
  const response = await proxy.execute(query);
  if (response) commit("setVendorUsers", response.data);
};

const deleteVendorUser = async ({ commit }, { vendorId, userId }) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/user/${userId}`;
  const response = await proxy.execute(query, {
    notificationParams: {
      displaySuccessMessage: true,
      successNotificationMsg: "Successfully deleted user!",
    },
  });
  if (response) {
    commit("updateVendorUsersList", {
      type: "DELETE",
      data: response.data,
    });
  }
};

const addVendorUser = async ({ commit }, payload) => {
  const { vendorId } = payload;
  const body = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email: payload.email,
    shippingTypeId: payload.shippingTypeId,
  };

  const proxy = new PostSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/user`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added user",
  });

  if (response) {
    commit("updateVendorUsersList", {
      type: "ADD",
      data: response.data,
    });
  }
};

const updateVendorUser = async ({ commit }, payload) => {
  const { vendorId, userId } = payload;
  const body = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email: payload.email,
    phone: payload.phone,
    shippingTypeId: payload.shippingTypeId,
  };

  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/user/${userId}`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully updated user.",
  });

  if (response) {
    commit("updateVendorUsersList", {
      type: "UPDATE",
      data: response.data,
    });
  }
};

const fetchLaboratories = async ({ commit }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = "accounts/v1/vendor/getlabs";
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setLaboratories", data);
    return data;
  }
};

const addVendorLogo = async (_, { vendorId, payload }) => {
  const proxy = new PostSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/logo`;
  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully added logo.",
  });
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchVendorList = async (_, { productCategory }) => {
  const query = "accounts/v1/vendors";
  // const { isSimpatraAdmin } = userMe.getMyRoles();
  // const orgId = localStorage.getItem("orgId");

  // Construct query parameters
  const queryParams = {
    associatedVendor: true,
  };
  globalUtil.addPropertyIfNotEmpty(queryParams, "productCategory", productCategory);
  // globalUtil.addPropertyIfNotEmpty(queryParams, "orgId", isSimpatraAdmin ? orgId : null);

  return await executeGetQuery(query, queryParams);
};

const fetchVendorShipping = async ({ commit }, { params }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${params.vendorId}/flat-rate-shipping`;
  const queryParams = {};
  if (params) {
    queryParams.status = params.status;
  }
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    commit("setVendorShipping", data);
    return data;
  }
};

const updateVendorShipping = async ({ commit }, { vendorId, vendorFlatRateShippingId, payload }) => {
  const proxy = new PutSimpatraServiceProxy();
  const query = `accounts/v1/vendor/${vendorId}/flat-rate-shipping/${vendorFlatRateShippingId}`;
  const response = await proxy.execute(query, payload, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully updated shipping.",
  });
  if (response) {
    commit("updateVendorShippingList", {
      type: "UPDATE",
      data: response.data,
    });
    return response.data;
  }
};

export default {
  fetchVendorUsers,
  deleteVendorUser,
  addVendorUser,
  updateVendorUser,
  fetchLaboratories,
  addVendorLogo,
  fetchVendorList,
  fetchVendorShipping,
  updateVendorShipping,
};
