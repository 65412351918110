import Vue from "vue";
import Vuex from "vuex";
import modules from "@/store/modules";

Vue.use(Vuex);

export const createStore = () => {
  return new Vuex.Store({
    modules: {
      ...modules.marketplace,
      ...modules.vendor,
      ...modules.organization,
      ...modules.location,
      ...modules.user,
      ...modules.admin,
      ...modules.medicalCenter,
      ...modules.other,
    },
  });
};

export default createStore();
