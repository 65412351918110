// import { get, merge } from "lodash";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

// Helper function to extract pagination details
// const extractPaginationDetails = (responseData, queryParams) => ({
//   pageNumber: queryParams.pageNumber ? queryParams.pageNumber : 0,
//   size: get(responseData, "pageSize", 5),
//   totalPages: get(responseData, "totalPages", 0),
//   totalData: get(responseData, "totalData", 0),
// });

// const commitData = (commit, setStore, responseData, paginationDetails) => {
//   const data = Array.isArray(responseData) ? responseData : get(responseData, "content", []);
//   commit(setStore, merge({ data }, paginationDetails));
//   return data;
// };

// const manageLoadingState = async (commit, action, callback) => {
//   commit("setCartState", { type: "isLoading", value: true });
//   const result = await callback();
//   commit("setCartState", { type: "isLoading", value: false });
//   return result;
// };
const executeGetQuery = async (query, queryParams = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const response = await proxy.execute(query, queryParams);
  return response ? response.data : null;
};

const executePostQuery = async (query, payload, notificationParams) => {
  const proxy = new PostSimpatraServiceProxy();
  const response = await proxy.execute(query, payload, notificationParams);
  return response ? response.data : null;
};

const executePutQuery = async (query, payload, notificationParams) => {
  const proxy = new PutSimpatraServiceProxy();
  const response = await proxy.execute(query, payload, notificationParams);
  return response ? response.data : null;
};

const executeDeleteQuery = async (query, notificationParams) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const response = await proxy.execute(query, { notificationParams });
  return response ? response.data : null;
};

const fetchCreditCards = async ({ commit }, queryParams) => {
  const query = "payments/v1/cards";
  const responseData = await executeGetQuery(query, queryParams);
  // const paginationDetails = extractPaginationDetails(responseData, queryParams);
  commit("setCreditCards", responseData.content);
  return responseData.content || [];
};

const deleteCreditCard = async (_, cardId) => {
  const query = `payments/v1/card/${cardId}`;
  const response = await executeDeleteQuery(query, {
    successNotificationMsg: "Successfully deleted credit card!",
    displaySuccessMessage: true,
  });
  return response;
};

const handleCreditCard = async (_, { payload, locationId, method }) => {
  // Buyer = Location
  const query = `payments/v1/card/buyer/${locationId}`;
  const notificationMsg = {
    successNotificationMsg: `Successfully ${method === "POST" ? "added" : "updated"} credit card!`,
    displaySuccessMessage: true,
  };
  const response = method === "POST" ? await executePostQuery(query, payload, notificationMsg) : await executePutQuery(query, payload, notificationMsg);
  return response;
};

const addCreditCard = (context, payload) => handleCreditCard(context, { ...payload, method: "POST" });
const updateCreditCard = (context, payload) => handleCreditCard(context, { ...payload, method: "PUT" });

export default {
  fetchCreditCards,
  deleteCreditCard,
  addCreditCard,
  updateCreditCard,
};
