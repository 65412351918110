import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const defaultPaginationDetails = {
  currentPage: 1,
  totalPages: 0,
  pageSize: 10,
  totalData: 0,
};

const initialReportFilters = {};

const createObject = () => ({
  data: [],
  initialReportFilters,
  filters: initialReportFilters,
  paginationDetails: defaultPaginationDetails,
});

export default {
  namespaced: true,
  state: {
    defaultPaginationDetails,
    patients: createObject(),
    patientList: [],
    totalPatient: null,
  },
  getters,
  mutations,
  actions,
};
