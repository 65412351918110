import store from "../store";

const initializeOrganization = async () => {
  const vendorLookup = store.getters["marketplaceModule/getVendorLookup"];
  if (vendorLookup.length < 1) {
    const activeMarketplaceSellers = await store.dispatch("accountsModule/fetchVendorList", { productCategory: "marketplace" });
    store.commit("marketplaceModule/setMarketplaceVendorLookup", activeMarketplaceSellers || []);
    store.commit("marketplaceModule/setBulkOrderVisibility", activeMarketplaceSellers && activeMarketplaceSellers.length > 0);
  }
};

export { initializeOrganization };
